import {
  Box,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import {
  SectionLayout,
  sectionProps,
} from "../../../components/page-sections/info-per-il-pubblico/layout";

const rowProps = {
  border: "1px solid",
  borderColor: "utils.gray.02",
};

const InadempimentiABFPage: React.FC = () => {
  return (
    <SectionLayout page="inadempimenti-abf" title="Inadempimenti ABF">
      <Box {...sectionProps}>
        <Stack spacing={4} direction={"column"} textAlign={"justify"}>
          <Heading
            as={"h1"}
            fontSize={["24px", "32px"]}
            fontWeight={"400"}
            textAlign={"left"}
            fontFamily={"body"}
            color={"accent.01"}
          >
            Inadempimenti ABF
          </Heading>

          <Table variant="unstyled">
            <Thead>
              <Tr
                bg={"accent.01"}
                color={"white"}
                border={"1px solid"}
                borderColor={"utils.gray.02"}
              >
                <Th py={2} px={2} fontFamily={"body"} fontWeight={"400"}>
                  Data
                </Th>

                <Th py={2} px={2} fontFamily={"body"} fontWeight={"400"}>
                  Decisione dell'Arbitro Bancario Finanziario
                </Th>

                <Th py={2} px={2} fontFamily={"body"} fontWeight={"400"}>
                  Note
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr {...rowProps}>
                <Td />
                <Td />
                <Td />
              </Tr>
              <Tr {...rowProps}>
                <Td />
                <Td />
                <Td />
              </Tr>
              <Tr {...rowProps}>
                <Td />
                <Td />
                <Td />
              </Tr>
              <Tr {...rowProps}>
                <Td />
                <Td />
                <Td />
              </Tr>
              <Tr {...rowProps}>
                <Td />
                <Td />
                <Td />
              </Tr>
              <Tr {...rowProps}>
                <Td />
                <Td />
                <Td />
              </Tr>
              <Tr {...rowProps}>
                <Td />
                <Td />
                <Td />
              </Tr>
              <Tr {...rowProps}>
                <Td />
                <Td />
                <Td />
              </Tr>
              <Tr {...rowProps}>
                <Td />
                <Td />
                <Td />
              </Tr>
              <Tr {...rowProps}>
                <Td />
                <Td />
                <Td />
              </Tr>
            </Tbody>
          </Table>
        </Stack>
      </Box>
    </SectionLayout>
  );
};

export default InadempimentiABFPage;
